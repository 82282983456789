










import { Component, Vue } from 'vue-property-decorator'

import StudyReviewTypeForm from '@/partials/forms/Events/StudyReviewTypeForm.vue'

@Component({
  components: {
    StudyReviewTypeForm
  }
})
export default class StudyReviewTypeCreate extends Vue {

}
